<!-- 车场信息-路外车场 -->
<template>
    <div class='content'>
        <parkingInformation :isCurPark="false"></parkingInformation>
    </div>
</template>
<script>
import parkingInformation from '../../../components/parkingInformation/index.vue'
export default {
  name: '',
  components: { parkingInformation },
  props: {},
  data () {
    return {
    }
  },
  watch: {},
  computed: {},
  methods: {},
  created () {},
  mounted () {}
}
</script>

<style scoped>

</style>
